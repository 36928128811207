// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// hooks
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
//
import Iconify from 'src/components/iconify';
import { HEADER } from '../config-layout';
//
import { HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  // const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
            backgroundColor: 'white',
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            // ...bgBlur({}),
            backgroundColor: 'white',
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: { xs: '100px', sm: '200px', md: '250px' },
              // height: { xs: '50px', sm: '60px', md: '120px' },
              mt: 2,
              ml: { xs: 2, md: 4 },
              // backgroundColor: 'primary.main',
            }}
          >
            <Link to="https://albarakamarketnc.com/">
              <img src="assets/images/home/nav-logo-white.png" alt="Home" />
            </Link>
          </Box>
          <Box
            sx={{
              mr: 3,
              display: 'flex',
              fontSize: '12px',
              justifyContent: 'start',
              alignItems: 'center',
              color: 'black',
            }}
          >
            <Iconify icon="eva:phone-fill" sx={{ color: 'black' }} /> +1 (919) 838-5155
          </Box>

          {/* <Box sx={{ flexGrow: 1 }} /> */}
        </Box>
      </Toolbar>
      <Divider sx={{ backgroundColor: 'white' }} />

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
