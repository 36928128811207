import { Navigate, useRoutes } from 'react-router-dom';

// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import MainLayout from 'src/layouts/main/layout';

import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // -------------------------------------------------------------------

    {
      path: '/',
      element: (
        // <AuthGuard>
        <MainLayout>
          <HomePage />
        </MainLayout>
        // </AuthGuard>
      ),
    },

    // Auth routes
    ...authRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
