// discountSlice.js
import { createSlice } from '@reduxjs/toolkit';

const discountSlice = createSlice({
  name: 'discount',
  initialState: {
    discountCheck: false,
    discount: null,
  },
  reducers: {
    addDiscountCheck: (state, action) => {
      state.discountCheck = action.payload;
    },
    addDiscountValue: (state, action) => {
      state.discount = action.payload;
    },
  },
});

export const { addDiscountCheck, addDiscountValue } = discountSlice.actions;
export default discountSlice.reducer;
