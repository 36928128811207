// cartSlice.js
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    menuItems: [],
    orderDetails: {},
    instructions: '',
    discountCheck: false,
    discount: null,
  },
  reducers: {
    selectMenuItems: (state, action) => {
      state.menuItems = action.payload;
    },
    addItem: (state, action) => {
      // const mystate = state.items;
      const existingItem = state.items.find(
        (item) => item.menuItemName === action.payload.menuItemName
      );
      if (existingItem && existingItem !== undefined) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },
    decreaseItem2: (state, action) => {
      const existingItem = state.items.find(
        (item) => item.menuItemName === action.payload.menuItemName
      );
      if (existingItem.quantity > 1) {
        existingItem.quantity -= 1;
      } else {
        state.items = state.items.filter(
          (item) => item.menuItemName !== action.payload.menuItemName
        );
      }
    },
    deleteAnItem: (state, action) => {
      state.items = state.items.filter((item) => item.menuItemName !== action.payload.menuItemName);
    },
    emptyItem: (state, action) => {
      state.items = [];
    },

    deleteItem: (state, action) => {
      state.items = state.items.filter((item) => item.name !== action.payload.name);
    },
    addOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    addInstructions: (state, action) => {
      state.instructions = action.payload;
    },
    modifiedModifiers: (state, action) => {
      const { myitem, modifiers } = action.payload;
      const existingIndex = state.items.findIndex(
        (item) => item.menuItemName === myitem.menuItemName
      );

      if (existingIndex !== -1) {
        state.items[existingIndex] = { ...myitem, modifiers };
      } else {
        state.items.push({ ...myitem, modifiers });
      }
    },
    addDiscountCheck: (state, action) => {
      state.discountCheck = action.payload;
    },
    addDiscountValue: (state, action) => {
      state.discount = action.payload;
    },
  },
});

export const {
  addOrderDetails,
  decreaseItem2,
  addItem,
  deleteItem,
  selectMenuItems,
  addInstructions,
  deleteAnItem,
  emptyItem,
  modifiedModifiers,
  addDiscountCheck,
  addDiscountValue,
} = cartSlice.actions;

export default cartSlice.reducer;
