/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartReducer from '../features/cart/cartSlice';
import discountReducer from '../features/discount/discountSlice';

const persistConfig = {
  key: 'root',
  storage,
};
// Persist config for discount (blacklisted)
const discountPersistConfig = {
  key: 'discount',
  storage,
  blacklist: ['discountCheck', 'discount'], // These will not be persisted
};

const persistedDiscountReducer = persistReducer(discountPersistConfig, discountReducer);

const persistedReducer = persistReducer(persistConfig, cartReducer);

const store = configureStore({
  reducer: {
    cart: persistedReducer,
    discount: persistedDiscountReducer,
  },
});

const persistor = persistStore(store);

export { store, persistor };
