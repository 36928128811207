// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 100,
  H_DESKTOP_OFFSET: 100,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
