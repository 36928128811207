import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// routes
// import { paths } from 'src/routes/paths';
// import { usePathname } from 'src/routes/hooks';
// components

export default function Footer() {
  // const pathname = usePathname();'alpha

  // const isHome = pathname === '/';

  // const simpleFooter = (
  //   <Box
  //     component="footer"
  //     sx={{
  //       py: 5,
  //       textAlign: 'center',
  //       position: 'relative',
  //       bgcolor: 'background.default',
  //     }}
  //   >
  //     <Container>
  //       <Typography variant="caption" component="div">
  //         © All rights reserved
  //         <br /> made by
  //         <Link href="/"> mitienda.cc </Link>
  //       </Typography>
  //     </Container>
  //   </Box>
  // );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: '#191919',
        color: 'white',
      }}
    >
      <Container
        sx={{
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Box
          sx={{ width: '100%', borderBottom: '1px solid', borderTop: '1px solid', py: 5, mt: 5 }}
        >
          <Grid
            container
            sx={{ width: '100%' }}
            justifyContent={{
              xs: 'space-between',
              md: 'space-between',
            }}
          >
            <Grid xs={6} md={6}>
              <Stack>
                <Typography sx={{ my: 1, fontWeight: 900 }}>Contact Info</Typography>
                <Typography sx={{ my: 1 }}>
                  Contact Info 3815 Hillsborough St Raleigh, North Carolina
                </Typography>
                <Typography sx={{ my: 1 }}>North Carolina +1 (919) 838-5155</Typography>
                {/* <Typography sx={{ my: 1 }}>albarakagrill@gmail.com</Typography> */}
              </Stack>
            </Grid>

            <Grid xs={6} md={6}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                }}
              >
                <Box>
                  <Typography sx={{ my: 1, fontWeight: 900 }}>Working Hours</Typography>
                  <Typography sx={{ my: 1 }}>Monday - Saturday: 11:00 AM to 09:00 PM</Typography>
                  <Typography sx={{ my: 1 }}>Sunday: 11:00 AM to 08:30 PM</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" sx={{ mt: 10 }}>
            Copyright © 1984-2022 All rights reserved
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            Follow us: &nbsp;
            <Box sx={{ width: '30px', height: '30px', cursor: 'pointer' }}>
              <a
                href="https://www.instagram.com/albarakamarketandgrill/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/images/home/instagram.png" alt="instagram" />
              </a>
            </Box>
            <Box sx={{ width: '30px', height: '30px', cursor: 'pointer' }}>
              <a
                href="https://www.facebook.com/albarakamarket1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/images/home/facebook.png" alt="facebook" />
              </a>
            </Box>
          </Typography>
        </Box>
      </Container>
    </Box>
  );

  // return isHome ? simpleFooter : mainFooter;
  return mainFooter;
}
