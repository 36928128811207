import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// import SimpleLayout from 'src/layouts/simple';
// import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const CheckOutPage = lazy(() => import('src/pages/checkout'));
const ConfirmedPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
// ----------------------------------------------------------------------a

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'confirmed', element: <ConfirmedPage /> },
      { path: 'payment_details', element: <ContactPage /> },
      { path: 'checkout', element: <CheckOutPage /> },
    ],
  },
  {
    element: (
      // <CompactLayout>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
      // </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
